import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import axiosInstance from "../api/axiosConfig";
import {
  initializeSocket,
  getSocket,
  joinChat,
  sendMessage as sendSocketMessage,
} from "../utils/socket";
import { useTheme } from "../contexts/ThemeContext";
import noImage from '../static/no-image.png';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImage, setProductImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [socketInitialized, setSocketInitialized] = useState(false);
  const { chatId } = useParams();
  const userId = localStorage.getItem("userId");
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const { darkMode } = useTheme();

  const fetchChatHistory = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/chat/${chatId}`);
      setMessages(response.data.messages || []);
      // Fetch product details
      const productResponse = await axiosInstance.get(
        `/api/inventory/${response.data.productId}`
      );
      setProductName(productResponse.data.productName);
      setProductId(productResponse.data._id);
      setProductPrice(productResponse.data.prices["1"]); // Assuming you want to show the price for 1 item
      setProductImage(productResponse.data.resourceList[0] 
        ? `${productResponse.data.resourceList[0]}` 
        : noImage);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setIsLoading(false);
    }
  }, [chatId]);

  useEffect(() => {
    console.log("Chat component mounted");
    
    // Initialize socket
    const token = localStorage.getItem('jwt'); // Assuming you store the JWT token in localStorage
    initializeSocket(token);
    setSocketInitialized(true);

    fetchChatHistory();

    return () => {
      console.log("Chat component unmounting");
      // Clean up socket connection if necessary
      const socket = getSocket();
      if (socket) {
        socket.disconnect();
      }
    };
  }, [fetchChatHistory]);

  useEffect(() => {
    if (socketInitialized) {
      const socket = getSocket();
      joinChat(chatId);

      socket.on("connect", () => {
        console.log("Socket connected");
      });

      socket.on("chat message", (message) => {
        console.log("Received new message:", message);
        setMessages((prevMessages) => {
          const messageExists = prevMessages.some(
            (m) =>
              m.senderId === message.senderId &&
              m.content === message.content &&
              Math.abs(new Date(m.timestamp) - new Date(message.timestamp)) < 1000
          );

          if (!messageExists) {
            return [...prevMessages, message];
          }
          return prevMessages;
        });
      });

      return () => {
        socket.off("chat message");
      };
    }
  }, [socketInitialized, chatId]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      if (inputMessage.trim() && socketInitialized) {
        try {
          console.log("Sending message:", inputMessage.trim());
          setInputMessage("");
          sendSocketMessage(chatId, inputMessage.trim());
        } catch (error) {
          console.error("Error sending message:", error);
        }
      }
    },
    [chatId, inputMessage, socketInitialized]
  );

  if (isLoading) {
    return (
      <div className={`flex items-center justify-center h-screen ${darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-black"}`}>
        Loading...
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col h-[calc(100vh-7rem)] ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-black"
      }`}
    >
      {/* Chat Header */}
      <div className={`${darkMode ? "bg-gray-800" : "bg-white"} shadow`}>
        <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
          <Link
            to={`/inventory/${productId}`}
            className="flex items-center space-x-4"
          >
            <div className="flex-shrink-0">
              <img
                src={productImage || "path/to/placeholder-image.jpg"}
                alt={productName}
                className="w-12 h-12 object-cover rounded"
              />
            </div>
            <div>
              <h2 className={`text-lg font-bold ${darkMode ? "text-white" : "text-gray-900"}`}>
                {productName || "Chat Room"}
              </h2>
              <p className={`text-sm ${darkMode ? "text-gray-300" : "text-gray-600"}`}>
                ${productPrice}
              </p>
            </div>
          </Link>
        </div>
      </div>

      {/* Scrollable Message Area */}
      <div className="flex-grow overflow-hidden">
        <div
          ref={chatContainerRef}
          className="h-full overflow-y-auto p-6 space-y-4"
        >
          {messages.length === 0 ? (
            <p className="text-center text-gray-500">No messages yet.</p>
          ) : (
            messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.senderId === userId ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  ref={index === messages.length - 1 ? lastMessageRef : null}
                  className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                    message.senderId === userId
                      ? darkMode
                        ? "bg-blue-600 text-white"
                        : "bg-blue-500 text-white"
                      : darkMode
                      ? "bg-gray-700 text-white"
                      : "bg-gray-300 text-black"
                  }`}
                >
                  <p className="font-bold">
                    {message.senderId === userId ? "You" : message.senderName}
                  </p>
                  <p>{message.content}</p>
                  <p
                    className={`text-xs mt-1 ${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    {new Date(message.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Fixed Footer with Input Field */}
      <div
        className={`${
          darkMode ? "bg-gray-800 border-gray-700" : "bg-white border-gray-200"
        } border-t px-4 py-4 sm:px-6`}
      >
        <form onSubmit={sendMessage} className="flex space-x-2">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type a message..."
            className={`flex-grow shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm ${
              darkMode
                ? "bg-gray-700 text-white border-gray-600"
                : "bg-white text-black border-gray-300"
            } rounded-md p-2`}
          />
          <button
            type="submit"
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
              darkMode
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-blue-500 hover:bg-blue-600"
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;