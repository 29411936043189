import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import './GoogleLoginButton.css';

const GoogleLoginButton = ({ onLogin }) => {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await axiosInstance.post('/api/auth/google', {
          code: codeResponse.code,
        });
        
        // Handle the response from your backend
        const { token, userId, username } = response.data;
        
        onLogin(token, userId, username);
        // Redirect to the dashboard or home page
        navigate('/');
      } catch (error) {
        console.error('Google Login Error:', error);
        // Handle login error (show error message to user)
      }
    },
    flow: 'auth-code',
  });

  return (
    <button className="google-login-button" onClick={() => login()}>
      <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google logo" />
      Sign in with Google
    </button>
  );
};

export default GoogleLoginButton;