import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Edit,
  Trash2,
  MessageCircle,
  User,
  Clock,
  Tag,
  FileText,
  DollarSign,
  Package,
  ShoppingCart,
  Cuboid,
} from "lucide-react";
import axiosInstance from "../api/axiosConfig";
import { useTheme } from "../contexts/ThemeContext";
import { formatDistanceToNow } from "date-fns";
import noImage from '../static/no-image.png';

const InventoryItemDetail = () => {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [IsSeller, setIsSeller] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sellerId, setSellerId] = useState(null);
  const [sellerName, setsellerName] = useState(null);
  const [productName, setProductName] = useState(null);
  const { darkMode } = useTheme();
  const [selectedImage, setSelectedImage] = useState(0);

  const [resourceList, setResourceList] = useState(null);
  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axiosInstance.get(`/api/inventory/${id}`);
        setItem(response.data);
        setIsSeller(response.data.sellerId === localStorage.getItem("userId"));
        setLoading(false);
        setSellerId(response.data.sellerId);
        setsellerName(response.data.sellerName);
        setProductName(response.data.productName);
        setResourceList(response.data.resourceList);
      } catch (err) {
        setError("Failed to fetch item details.");
        setLoading(false);
      }
    };
  
    fetchItem();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axiosInstance.delete(`/api/inventory/${id}`);
        navigate("/");
      } catch (err) {
        setError("Failed to delete item.");
      }
    }
  };

  const formatPostedTime = (createdAt) => {
    return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-10">{error}</div>;
  }

  if (!item) {
    return <div className="text-center mt-10">Item not found.</div>;
  }

  const handleContactSeller = async () => {
    console.log("sellerId in handleContactSeller:", sellerId);
    try {
      const response = await axiosInstance.post("/api/chat/start", {
        sellerId,
        sellerName,
        productName,
        productId: id,
      });

      navigate(`/chat/${response.data.chatId}`);
    } catch (error) {
      navigate("/login");
      console.error("Error contacting seller:", error);
    }
  };

  return (
    <div
      className={`${
        darkMode ? "bg-gray-800" : "bg-white"
      } shadow-lg rounded-lg overflow-hidden`}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-1/2 p-4">
          <div className="relative pb-[75%] mb-4">
            <img
              className="absolute top-0 left-0 w-full h-full object-contain rounded-lg"
              src={
                resourceList.length > 0
                  ? `${resourceList[selectedImage]}`
                  : noImage
              }
              alt={item.productName}
            />
          </div>
          <div className="flex space-x-2 overflow-x-auto py-2">
            {resourceList.map((resource, index) => (
              <img
                key={index}
                src={`${resource}`}
                alt={`${item.productName} - ${index + 1}`}
                className={`w-16 h-16 md:w-20 md:h-20 object-cover rounded-md cursor-pointer flex-shrink-0 ${
                  selectedImage === index
                    ? "border-2 border-blue-500"
                    : "border border-gray-300"
                }`}
                onClick={() => setSelectedImage(index)}
              />
            ))}
          </div>
        </div>
        {/* Item details section */}
        <div className="lg:w-1/2 p-6 flex flex-col">
          <h1
            className={`text-2xl font-bold mb-4 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            <Cuboid size={24} className="inline mr-2" />
            {item.productName}
          </h1>

          <p className={`mb-4 ${darkMode ? "text-gray-300" : "text-gray-600"}`}>
            <FileText size={16} className="inline mr-2" />
            {item.description}
          </p>

          <p className={`mb-2 ${darkMode ? "text-gray-200" : "text-gray-700"}`}>
            <Tag size={16} className="inline mr-2" />
            Category: <span className="font-bold">{item.category}</span>
          </p>

          <p className={`mb-2 ${darkMode ? "text-gray-200" : "text-gray-700"}`}>
            <DollarSign size={16} className="inline mr-2" />
            Price (1 item):{" "}
            <span className="font-bold">${item.prices["1"]}</span>
          </p>

          <p className={`mb-2 ${darkMode ? "text-gray-200" : "text-gray-700"}`}>
            <ShoppingCart size={16} className="inline mr-2" />
            Price (10 items):{" "}
            <span className="font-bold">${item.prices["10"]}</span>
          </p>

          <p className={`mb-2 ${darkMode ? "text-gray-200" : "text-gray-700"}`}>
            <Package size={16} className="inline mr-2" />
            In Stock: <span className="font-bold">{item.count}</span>
          </p>


          <p className={`mb-2 ${darkMode ? "text-gray-200" : "text-gray-700"}`}>
            <User size={16} className="inline mr-2" />
            Seller: <Link to={`/user/${item.sellerId}`} className={`font-bold ${darkMode ? "text-blue-400 hover:text-blue-300" : "text-blue-600 hover:text-blue-700"}`}>
              {item.sellerName}
            </Link>
          </p>

          <p className={`mb-4 ${darkMode ? "text-gray-200" : "text-gray-700"}`}>
            <Clock size={16} className="inline mr-2" />
            Posted:{" "}
            <span className="font-bold">
              {formatPostedTime(item.createdAt)}
            </span>
          </p>

          {/* Buttons section */}
          <div className="mt-4 flex space-x-4">
            {IsSeller ? (
              <>
                <Link to={`/edit/${item._id}`}>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center px-4 py-2 ${
                      darkMode
                        ? "bg-blue-600 hover:bg-blue-700"
                        : "bg-blue-500 hover:bg-blue-600"
                    } text-white rounded transition duration-300`}
                  >
                    <Edit size={18} className="mr-2" />
                    Edit
                  </motion.button>
                </Link>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleDelete}
                  className={`flex items-center px-4 py-2 ${
                    darkMode
                      ? "bg-red-600 hover:bg-red-700"
                      : "bg-red-500 hover:bg-red-600"
                  } text-white rounded transition duration-300`}
                >
                  <Trash2 size={18} className="mr-2" />
                  Delete
                </motion.button>
              </>
            ) : (
              <motion.button
                onClick={handleContactSeller}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`flex items-center px-4 py-2 ${
                  darkMode
                    ? "bg-green-600 hover:bg-green-700"
                    : "bg-green-500 hover:bg-green-600"
                } text-white rounded transition duration-300`}
              >
                <MessageCircle size={18} className="mr-2" />
                Chat with Seller
              </motion.button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryItemDetail;
