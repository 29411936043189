import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Upload, CheckCircle, AlertCircle } from 'lucide-react';
import customFetch from '../fetchConfig';
import { useTheme } from '../contexts/ThemeContext';

const CSVImport = () => {
  const [file, setFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [importResult, setImportResult] = useState(null);
  const { darkMode } = useTheme();
  
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please select a CSV file to import.');
      return;
    }

    setImporting(true);
    setImportResult(null);

    const formData = new FormData();
    formData.append('csvFile', file);

    try {
      const { data } = await customFetch('/api/inventory/import', {
        method: 'POST',
        body: formData,
        headers: {
        },
      });
      setImportResult({ success: true, message: data.message });
    } catch (error) {
      setImportResult({ success: false, message: error.message || 'An error occurred during import.' });
    } finally {
      setImporting(false);
    }
  };

  return (
    <div className={`mt-8 p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md`}>
      <h3 className={`text-xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Batch Import Items</h3>
      <form onSubmit={handleImport} className="space-y-4">
        <div>
          <label htmlFor="csvFile" className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Select CSV File
          </label>
          <input
            type="file"
            id="csvFile"
            accept=".csv"
            onChange={handleFileChange}
            className={`mt-1 block w-full text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      ${darkMode 
                        ? 'file:bg-blue-900 file:text-blue-300 hover:file:bg-blue-800' 
                        : 'file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100'}`}
          />
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          disabled={importing || !file}
          className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                      ${importing || !file 
                        ? 'bg-gray-600 cursor-not-allowed' 
                        : darkMode
                          ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                          : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}
        >
          {importing ? (
            <>
              <Upload className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              Importing...
            </>
          ) : (
            <>
              <Upload className="-ml-1 mr-3 h-5 w-5" />
              Import CSV
            </>
          )}
        </motion.button>
      </form>
      {importResult && (
        <div className={`mt-4 p-4 rounded-md ${
          importResult.success 
            ? darkMode ? 'bg-green-900 text-green-200' : 'bg-green-50 text-green-800'
            : darkMode ? 'bg-red-900 text-red-200' : 'bg-red-50 text-red-800'
        }`}>
          {importResult.success ? (
            <CheckCircle className="inline-block mr-2" size={20} />
          ) : (
            <AlertCircle className="inline-block mr-2" size={20} />
          )}
          {importResult.message}
        </div>
      )}
    </div>
  );
};

export default CSVImport;