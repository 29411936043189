import React, { useState, useEffect } from 'react';
import { useParams, } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import { useTheme } from '../contexts/ThemeContext';
import { formatDistanceToNow, parseISO } from 'date-fns';
import ItemList from './ItemList';

const UserProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const { darkMode } = useTheme();
  const loggedInUserId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userResponse = await axiosInstance.get(`/api/users/${userId}`);
        setUser(userResponse.data);
        setUsername(userResponse.data.username);

        const inventoryResponse = await axiosInstance.get(`/api/users/${userId}/inventory`);
        setInventory(inventoryResponse.data.items);
      } catch (err) {
        setError('Failed to fetch user information');
        console.error(err);
      }
    };

    fetchUserInfo();
  }, [userId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setUsername(user.username);
    setOldPassword('');
    setNewPassword('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`/api/users/${userId}`, {
        username,
        oldPassword: oldPassword || undefined,
        newPassword: newPassword || undefined
      });
      setUser(response.data.user);
      setIsEditing(false);
      setOldPassword('');
      setNewPassword('');
    } catch (err) {
      setError('Failed to update user information');
      console.error(err);
    }
  };

  if (error) return <div className="text-red-500">{error}</div>;
  if (!user) return <div>Loading...</div>;

  const joinedDate = user.createdAt ? formatDistanceToNow(parseISO(user.createdAt), { addSuffix: true }) : 'Unknown';

  return (
    <div className={`max-w-4xl mx-auto mt-10 p-6 ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} rounded-lg shadow-md`}>
      <h2 className="text-2xl font-bold mb-4">User Profile</h2>
      <div className="mb-6">
        <p><strong>Username:</strong> {user.username}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Joined:</strong> {joinedDate}</p>
        {userId === loggedInUserId && !isEditing && (
          <button onClick={handleEdit} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Edit Profile</button>
        )}
      </div>

      {isEditing && userId === loggedInUserId && (
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="mb-4">
            <label className="block mb-2">Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`w-full p-2 ${darkMode ? 'bg-gray-600 text-white' : 'bg-gray-100 text-gray-900'} rounded`}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Old Password (optional):</label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className={`w-full p-2 ${darkMode ? 'bg-gray-600 text-white' : 'bg-gray-100 text-gray-900'} rounded`}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">New Password (optional):</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={`w-full p-2 ${darkMode ? 'bg-gray-600 text-white' : 'bg-gray-100 text-gray-900'} rounded`}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button type="button" onClick={handleCancel} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600">Cancel</button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Save</button>
          </div>
        </form>
      )}

      <h3 className="text-xl font-bold mb-4">Listing</h3>
      <ItemList 
        items={inventory}
        loading={false}
        darkMode={darkMode}
        showSellerName={false}
        gridCols="grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
      />
    </div>
  );
};

export default UserProfile;