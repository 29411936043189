const ProductCategory = Object.freeze({
    ELECTRONICS: "Electronics",
    COMPUTERS: "Computers",
    ACCESSORIES: "Accessories",
    HOME_APPLIANCES: "Home Appliances",
    GAMING: "Gaming",
    MOBILE_DEVICES: "Mobile Devices",
    AUDIO: "Audio",
    FURNITURE: "Furniture",
    WEARABLES: "Wearables",
    OFFICE_SUPPLIES: "Office Supplies",
    OTHER: "Other",
  });
  
  export default ProductCategory;