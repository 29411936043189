import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from "../api/axiosConfig";
import { useTheme } from '../contexts/ThemeContext';
import { TrashIcon } from 'lucide-react';

const Inbox = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { darkMode } = useTheme();

  const fetchChats = useCallback(async () => {
    console.log("Attempting to fetch chats...");
    try {
      const response = await axiosInstance.get('/api/chats');
      console.log("Chats fetched successfully:", response.data);
      setChats(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching chats:", err);
      setError("Failed to load chats. Please try again.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log("Inbox component mounted");
    fetchChats();
  }, [fetchChats]);

  const handleDelete = async (chatId) => {
    if (window.confirm("Are you sure you want to delete this chat?")) {
      try {
        await axiosInstance.delete(`/api/chat/${chatId}`);
        setChats(chats.filter(chat => chat._id !== chatId));
      } catch (err) {
        console.error("Error deleting chat:", err);
        setError("Failed to delete chat. Please try again.");
      }
    }
  };

  if (loading) return <div className={`text-center mt-8 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Loading...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className={`max-w-2xl mx-auto mt-8 ${darkMode ? 'text-white' : 'text-gray-800'}`}>
      <h1 className="text-3xl font-bold mb-6">Inbox</h1>
      {chats.length === 0 ? (
        <p className="text-center">No chats found.</p>
      ) : (
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg overflow-hidden`}>
          {chats.map((chat) => (
            <div 
              key={chat._id} 
              className={`flex items-center ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition duration-150 ease-in-out`}
            >
              <Link 
                to={`/chat/${chat._id}`} 
                className="flex-grow"
              >
                <div className={`px-6 py-4 flex items-center border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                  <div className="flex-grow">
                    <h2 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                      {chat.productName}
                    </h2>
                    <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      Seller: {chat.sellerName}
                    </p>
                  </div>
                  <div className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {new Date(chat.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </Link>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(chat._id);
                }}
                className={`p-2 mr-4 rounded-full ${darkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
                aria-label="Delete chat"
              >
                <TrashIcon className={`w-5 h-5 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Inbox;