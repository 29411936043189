import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Home, Inbox, LogOut, LogIn, UserPlus, Moon, Sun } from 'lucide-react';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Components
import Login from "./components/Login";
import Register from "./components/Register";
import InventoryList from "./components/InventoryList";
import AddInventoryItem from "./components/AddInventoryItem";
import InventoryItemDetail from "./components/InventoryItemDetail";
import EditInventoryItem from "./components/EditInventoryItem";
import Chat from "./components/Chat";
import InboxComponent from './components/Inbox';
import UserProfile from './components/UserProfile';

const AppContent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const { darkMode, toggleDarkMode } = useTheme();

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const storedUsername = localStorage.getItem("username");
    const storedUserId = localStorage.getItem("userId");
    if (token && storedUsername) {
      setIsAuthenticated(true);
      setUsername(storedUsername);
      setUserId(storedUserId);
    }
  }, []);

  const handleLogin = (token, userId, username) => {
    localStorage.setItem("jwt", token);
    localStorage.setItem("username", username);
    localStorage.setItem("userId", userId);
    setIsAuthenticated(true);
    setUserId(userId);
    setUsername(username);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    setIsAuthenticated(false);
    setUsername("");
  };

  return (
    <Router>
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <nav className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex justify-between h-16">
      <div className="flex items-center">
        <Link to="/" className="flex items-center text-xl font-bold">
          <Home className="mr-2" />
          <span className="hidden sm:inline">Inventory</span>
        </Link>
        <Link to="/inbox" className="ml-6 flex items-center">
          <Inbox className="mr-2" />
          <span className="hidden sm:inline">Inbox</span>
        </Link>
      </div>
      <div className="flex items-center">
        {isAuthenticated ? (
          <>
            <span className="mr-4 hidden sm:inline">Welcome, <Link to={`/user/${userId}`}>{username}</Link></span>
            <button
              onClick={handleLogout}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center"
            >
              <LogOut className="mr-2" size={18} />
              <span className="hidden sm:inline">Logout</span>
            </button>
          </>
        ) : (
          <>
            <Link
              to="/login"
              className="text-gray-700 hover:text-gray-900 mr-4 flex items-center"
            >
              <LogIn className="mr-2" size={18} />
              <span className="hidden sm:inline">Login</span>
            </Link>
            <Link
              to="/register"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center"
            >
              <UserPlus className="mr-2" size={18} />
              <span className="hidden sm:inline">Register</span>
            </Link>
          </>
        )}
        <button
          onClick={toggleDarkMode}
          className="ml-4 p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-300"
        >
          {darkMode ? <Sun size={24} /> : <Moon size={24} />}
        </button>
      </div>
    </div>
  </div>
</nav>

        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<InventoryList />} />
            <Route
              path="/add"
              element={
                isAuthenticated ? (
                  <AddInventoryItem />
                ) : (
                  <Login onLogin={handleLogin} />
                )
              }
            />
            <Route
              path="/inventory/:id"
              element={ <InventoryItemDetail />}
            />
            <Route
              path="/edit/:id"
              element={
                isAuthenticated ? (
                  <EditInventoryItem />
                ) : (
                  <Login onLogin={handleLogin} />
                )
              }
            />
            <Route
              path="/chat/:chatId"
              element={
                isAuthenticated ? <Chat /> : <Login onLogin={handleLogin} />
              }
            />
            <Route
              path="/inbox"
              element={
                isAuthenticated ? <InboxComponent /> : <Login onLogin={handleLogin} />
              }
            />
            <Route
              path="/user/:userId"
              element={<UserProfile />}
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

const App = () => {
  return (
    <GoogleOAuthProvider clientId="1016497907237-oh809ig2s4bvmvt9fa31mudevd6qt61a.apps.googleusercontent.com">
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </GoogleOAuthProvider> 
  );
};

export default App;
