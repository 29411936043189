import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight, Plus, RefreshCw, Search, ArrowLeft, } from "lucide-react";
import customFetch from "../fetchConfig";
import { useTheme } from '../contexts/ThemeContext';
import ItemList from './ItemList';
import ProductCategory from '../enums/ProductCategory';

const InventoryList = () => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();
  const defaultSize = 8;
  const { darkMode } = useTheme();

  const handleFetchError = useCallback((err) => {
    if (err.name === "AbortError") {
      setError("Request timed out. Please try again.");
    } else if (err.response && err.response.status === 401) {
      navigate("/login");
    } else {
      setError(`Failed to fetch inventory items: ${err.message}`);
    }
    setLoading(false);
  }, [navigate]);

  const fetchInventoryItems = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await customFetch(`/api/inventory?page=${currentPage}&size=${defaultSize}`);
      setItems(data.items);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (err) {
      handleFetchError(err);
    }
  }, [currentPage, handleFetchError]);

  const searchItems = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await customFetch(`/api/inventory-search?q=${encodeURIComponent(searchQuery)}&category=${selectedCategory}&page=${currentPage}&size=${defaultSize}`);
      setItems(data.results);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (err) {
      handleFetchError(err);
    }
  }, [searchQuery, selectedCategory, currentPage, handleFetchError]);

  useEffect(() => {
    if (!isSearching) {
      fetchInventoryItems();
    }
  }, [fetchInventoryItems, isSearching]);

  useEffect(() => {
    if (isSearching) {
      searchItems();
    }
  }, [searchItems, isSearching]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRefresh = () => {
    setSearchQuery("");
    setSelectedCategory("All");
    setIsSearching(false);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setIsSearching(true);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleBackToInventory = () => {
    setSearchQuery("");
    setSelectedCategory("All");
    setIsSearching(false);
    setCurrentPage(1);
  };

  const renderSelect = (id, value, onChange, options, defaultOption = "All") => (
    <div className="relative">
      <select
        id={id}
        value={value}
        onChange={onChange}
        className={`appearance-none bg-transparent border ${
          darkMode ? 'border-gray-600 text-white' : 'border-gray-300 text-gray-900'
        } rounded-md px-3 py-2 mr-1 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500`}
        style={{
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          background: darkMode ? '#374151' : 'white',
        }}
      >
        <option value="">{defaultOption}</option>
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={value} className={darkMode ? 'bg-gray-700' : 'bg-white'}>
            {value}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
        </svg>
      </div>
    </div>
  );

  const renderPageNumbers = () => {
    let pages = [];
    const maxPageButtons = 4;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <motion.button
          key={i}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded-md ${
            i === currentPage 
              ? darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'
              : darkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-700'
          }`}
        >
          {i}
        </motion.button>
      );
    }
    return pages;
  };

  return (
    <div className={`container mx-auto px-4 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Inventory Items</h2>
        <Link to="/add">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`${darkMode ? 'bg-green-700 hover:bg-green-800' : 'bg-green-500 hover:bg-green-600'} text-white font-bold py-2 px-4 rounded inline-flex items-center`}
          >
            <Plus size={20} className="mr-2" />
            Add
          </motion.button>
        </Link>
      </div>

      <form onSubmit={handleSearch} className="mb-6">
        <div className={`flex items-center border-b border-b-2 ${darkMode ? 'border-blue-400' : 'border-blue-500'} py-2`}>
          <input
            className={`appearance-none bg-transparent border-none w-full ${darkMode ? 'text-white' : 'text-gray-700'} mr-3 py-1 px-2 leading-tight focus:outline-none`}
            type="text"
            placeholder="Search for products"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {renderSelect(
            "category",
            selectedCategory,
            handleCategoryChange,
            ProductCategory,
            "All Categories"
          )}
          <button
            className={`flex-shrink-0 ${darkMode ? 'bg-blue-600 hover:bg-blue-800' : 'bg-blue-500 hover:bg-blue-700'} border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded`}
            type="submit"
          >
            <Search size={20} />
          </button>
        </div>
      </form>

      {isSearching && (
        <button
          onClick={handleBackToInventory}
          className={`mb-4 flex items-center ${darkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-700'}`}
        >
          <ArrowLeft size={20} className="mr-2" />
          Back to Inventory
        </button>
      )}

      {error && (
        <div className="text-center mt-10 mb-10">
          <p className="text-red-500 mb-4">{error}</p>
          <button
            onClick={handleRefresh}
            className={`${darkMode ? 'bg-blue-600 hover:bg-blue-800' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded flex items-center justify-center mx-auto`}
          >
            <RefreshCw size={20} className="mr-2" />
            Retry
          </button>
        </div>
      )}

      <ItemList 
        items={items}
        loading={loading}
        darkMode={darkMode}
      />

      <div className="flex justify-center items-center mt-8">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`mr-2 px-2 py-1 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-md disabled:opacity-50 transition duration-300`}
        >
          <ChevronLeft size={20} />
        </motion.button>
        
        {renderPageNumbers()}
        
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`ml-2 px-2 py-1 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-md disabled:opacity-50 transition duration-300`}
        >
          <ChevronRight size={20} />
        </motion.button>
      </div>
    </div>
  );
};

export default InventoryList;