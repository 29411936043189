import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { User, Clock } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import noImage from '../static/no-image.png';

const ItemList = ({ 
  items, 
  loading, 
  darkMode, 
  showSellerName = true,
  gridCols = "grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
}) => {
  const formatPostedTime = (createdAt) => {
    return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
  };

  if (loading) {
    return <p className={`text-center ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Loading...</p>;
  }

  if (items.length === 0) {
    return <p className={`text-center ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>No items found.</p>;
  }

  return (
    <div className={`grid ${gridCols} gap-6`}>
      {items.map((item) => (
        <Link
          key={item._id}
          to={`/inventory/${item._id}`}
          className="block"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-lg shadow-md transition duration-300 ease-in-out h-full flex flex-col`}
          >
            <img
              src={
                item.resourceList && item.resourceList.length > 0
                  ? `${item.resourceList[0]}`
                  : noImage
              }
              alt={item.productName}
              className="w-full h-40 object-cover mb-4 rounded"
            />
            <h3 className={`text-lg font-semibold mb-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>{item.productName}</h3>
            <p className={`${darkMode ? 'text-gray-200' : 'text-gray-800'} font-bold mb-2`}>
              ${item.prices["1"]}
            </p>
            {showSellerName && (
              <div className={`flex items-center ${darkMode ? 'text-gray-300' : 'text-gray-600'} text-sm mb-1`}>
                <User size={14} className="mr-1" />
                <span>{item.sellerName}</span>
              </div>
            )}
            <div className={`flex items-center ${darkMode ? 'text-gray-300' : 'text-gray-600'} text-sm`}>
              <Clock size={14} className="mr-1" />
              <span>{formatPostedTime(item.createdAt)}</span>
            </div>
          </motion.div>
        </Link>
      ))}
    </div>
  );
};

export default ItemList;