import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { X, Upload } from "lucide-react";
import customFetch from "../fetchConfig";
import axiosInstance from "../api/axiosConfig";
import { useTheme } from "../contexts/ThemeContext";
import ProductCategory from "../enums/ProductCategory";
import ProductStatus from "../enums/ProductStatus";

const EditInventoryItem = () => {
  const [item, setItem] = useState(null);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [price1, setPrice1] = useState("");
  const [price10, setPrice10] = useState("");
  const [count, setCount] = useState("");
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [status, setStatus] = useState("Active");
  const [category, setCategory] = useState("Other");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const { darkMode } = useTheme();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const { data } = await customFetch(`/api/inventory/${id}`);
        setItem(data);
        setProductName(data.productName);
        setDescription(data.description);
        setPrice1(data.prices["1"]);
        setPrice10(data.prices["10"]);
        setCount(data.count);
        setImages(data.resourceList);
        setStatus(data.status || "Active"); // Default to Active if not provided
        setCategory(data.category || "Other"); // Default to Other if not provided
      } catch (err) {
        setError("Failed to fetch item details.");
      }
    };

    fetchItem();
  }, [id]);

  const renderSelect = (id, label, value, onChange, options) => (
    <div className="mb-4">
      <label
        htmlFor={id}
        className={`block ${
          darkMode ? "text-gray-200" : "text-gray-700"
        } text-sm font-bold mb-2`}
      >
        {label}
      </label>
      <select
        id={id}
        value={value}
        onChange={onChange}
        className={`w-full px-3 py-2 border ${
          darkMode
            ? "border-gray-600 bg-gray-700 text-white"
            : "border-gray-300 bg-white text-gray-900"
        } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
        required
      >
        <option value="">Select {label}</option>
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("description", description);
    formData.append(
      "prices",
      JSON.stringify({ 1: parseFloat(price1), 10: parseFloat(price10) })
    );
    formData.append("count", count);
    formData.append("status", status);
    formData.append("category", category);
    formData.append("resourceList", JSON.stringify(images));

    // Append new images
    newImages.forEach((image, index) => {
      formData.append(`images`, image);
    });

    try {
      await axiosInstance.put(`/api/inventory/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      navigate(`/inventory/${id}`);
    } catch (err) {
      setError("Failed to update item. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleNewImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setNewImages([...newImages, ...files]);
  };

  const handleRemoveNewImage = (index) => {
    setNewImages(newImages.filter((_, i) => i !== index));
  };

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`max-w-2xl mx-auto mt-10 ${
        darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
      } p-8 border ${
        darkMode ? "border-gray-700" : "border-gray-300"
      } rounded-lg shadow-lg`}
    >
      <h2 className="text-2xl font-bold mb-6 text-center">
        Edit Inventory Item
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="productName"
            className={`block ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } text-sm font-bold mb-2`}
          >
            Product Name
          </label>
          <input
            type="text"
            id="productName"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className={`w-full px-3 py-2 border ${
              darkMode
                ? "border-gray-600 bg-gray-700 text-white"
                : "border-gray-300 bg-white text-gray-900"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className={`block ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } text-sm font-bold mb-2`}
          >
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`w-full px-3 py-2 border ${
              darkMode
                ? "border-gray-600 bg-gray-700 text-white"
                : "border-gray-300 bg-white text-gray-900"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            rows="3"
            required
          ></textarea>
        </div>
        <div className="mb-4 flex space-x-4">
          <div className="flex-1">
            <label
              htmlFor="price1"
              className={`block ${
                darkMode ? "text-gray-200" : "text-gray-700"
              } text-sm font-bold mb-2`}
            >
              Price (1 item)
            </label>
            <input
              type="number"
              id="price1"
              value={price1}
              onChange={(e) => setPrice1(e.target.value)}
              className={`w-full px-3 py-2 border ${
                darkMode
                  ? "border-gray-600 bg-gray-700 text-white"
                  : "border-gray-300 bg-white text-gray-900"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
              step="0.01"
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="price10"
              className={`block ${
                darkMode ? "text-gray-200" : "text-gray-700"
              } text-sm font-bold mb-2`}
            >
              Price (10 items)
            </label>
            <input
              type="number"
              id="price10"
              value={price10}
              onChange={(e) => setPrice10(e.target.value)}
              className={`w-full px-3 py-2 border ${
                darkMode
                  ? "border-gray-600 bg-gray-700 text-white"
                  : "border-gray-300 bg-white text-gray-900"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
              step="0.01"
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="count"
            className={`block ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } text-sm font-bold mb-2`}
          >
            Count
          </label>
          <input
            type="number"
            id="count"
            value={count}
            onChange={(e) => setCount(e.target.value)}
            className={`w-full px-3 py-2 border ${
              darkMode
                ? "border-gray-600 bg-gray-700 text-white"
                : "border-gray-300 bg-white text-gray-900"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            required
          />
        </div>
        {renderSelect(
          "status",
          "Status",
          status,
          (e) => setStatus(e.target.value),
          ProductStatus
        )}
        {renderSelect(
          "category",
          "Category",
          category,
          (e) => setCategory(e.target.value),
          ProductCategory
        )}
        <div className="mb-6">
          <label
            className={`block ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } text-sm font-bold mb-2`}
          >
            Current Images
          </label>
          <div className="grid grid-cols-2 gap-4">
            {images.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={`${image}`}
                  alt={`Product ${index + 1}`}
                  className="w-full h-32 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-6">
          <label
            className={`block ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } text-sm font-bold mb-2`}
          >
            Upload New Images
          </label>
          <input
            type="file"
            multiple
            onChange={handleNewImageUpload}
            className={`w-full px-3 py-2 border ${
              darkMode
                ? "border-gray-600 bg-gray-700 text-white"
                : "border-gray-300 bg-white text-gray-900"
            } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            accept="image/*"
          />
          <div className="mt-4 grid grid-cols-2 gap-4">
            {newImages.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(image)}
                  alt={`New upload ${index + 1}`}
                  className="w-full h-32 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveNewImage(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <motion.button
          whileHover={{ scale: isLoading ? 1 : 1.05 }}
          whileTap={{ scale: isLoading ? 1 : 0.95 }}
          type="submit"
          disabled={isLoading}
          className={`w-full ${
            darkMode
              ? "bg-blue-600 hover:bg-blue-700"
              : "bg-blue-500 hover:bg-blue-600"
          } text-white font-bold py-2 px-4 rounded-md transition duration-300 flex items-center justify-center ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Updating...
            </>
          ) : (
            <>
              <Upload size={20} className="mr-2" />
              Update Item
            </>
          )}
        </motion.button>
      </form>
    </div>
  );
};

export default EditInventoryItem;
