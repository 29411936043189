import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axiosInstance from '../api/axiosConfig';
import { useTheme } from '../contexts/ThemeContext';
import GoogleLoginButton from '../components/GoogleLogin';

const Login = ({ onLogin }) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  // const dispatch = useDispatch();
  const { darkMode } = useTheme();
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/api/login', credentials, {});
      if (response.data) {
        onLogin(response.data.token, response.data.userId, response.data.username);
        // Navigate to the home page
        navigate('/');
      } else {
        throw new Error('Login failed: No token received');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className={`min-h-screen flex items-center justify-center ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className={`max-w-md w-full mx-auto ${darkMode ? 'bg-gray-800' : 'bg-white'} p-8 ${darkMode ? 'border-gray-700' : 'border-gray-300'} border rounded-lg shadow-lg`}>
        <h2 className={`text-2xl font-bold mb-6 text-center ${darkMode ? 'text-white' : 'text-gray-900'}`}>Login</h2>
        <form onSubmit={handleSubmit}>
        <div className="mb-4">
  <label htmlFor="email" className={`block ${darkMode ? 'text-gray-300' : 'text-gray-700'} text-sm font-bold mb-2`}>
    Email
  </label>
  <input
    type="email"
    name="email"
    value={credentials.email}
    onChange={handleChange}
    placeholder="Email"
    className={`w-full px-3 py-2 ${
      darkMode 
        ? 'bg-gray-700 border-gray-600 text-white' 
        : 'bg-white border-gray-300 text-gray-900'
    } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border`}
    required
  />
</div>
<div className="mb-6">
  <label htmlFor="password" className={`block ${darkMode ? 'text-gray-300' : 'text-gray-700'} text-sm font-bold mb-2`}>
    Password
  </label>
  <input
    type="password"
    name="password"
    value={credentials.password}
    onChange={handleChange}
    placeholder="Password"
    className={`w-full px-3 py-2 ${
      darkMode 
        ? 'bg-gray-700 border-gray-600 text-white' 
        : 'bg-white border-gray-300 text-gray-900'
    } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border`}
    required
  />
</div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className={`w-full ${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded-md transition duration-300`}
          >
            Login
          </motion.button>
        </form>
        <div className="mt-4">
          <GoogleLoginButton onLogin={ onLogin } />
        </div>
      </div>
    </div>
  );
};

export default Login;