const API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

const customFetch = async (url, options = {}) => {
  const defaultOptions = {
    headers: {
      "Accept": "*/*",
    },
    // credentials: "include",
  };

  const mergedOptions = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...options.headers,
    },
  };

  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    mergedOptions.headers.Authorization = `Bearer ${jwt}`;
  }

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout

  try {
    const networkInfo = {
      online: navigator.onLine,
      connectionType: navigator.connection
        ? navigator.connection.effectiveType
        : "unknown",
      saveData: navigator.connection
        ? navigator.connection.saveData
        : "unknown",
    };

    console.log("Network Info:", networkInfo);
    console.log("Fetch Request:", {
      url: `${API_BASE_URL}${url}`,
      method: mergedOptions.method || "GET",
      headers: mergedOptions.headers,
    });

    const response = await fetch(`${API_BASE_URL}${url}`, {
      ...mergedOptions,
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    console.log("Fetch Response:", {
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries(response.headers.entries()),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      const error = new Error(`HTTP error! status: ${response.status}`);
      error.response = response;
      error.responseBody = errorBody;
      throw error;
    }

    const data = await response.json();
    console.log("Fetch Response Data:", data);
    return { data, response };
  } catch (error) {
    clearTimeout(timeoutId);
    console.error("Fetch error:", error);
    if (error.name === "AbortError") {
      console.error("Request timed out");
    } else if (
      error.name === "TypeError" &&
      error.message === "Failed to fetch"
    ) {
      console.error("Network error details:", {
        online: navigator.onLine,
        API_BASE_URL,
        url,
      });
    }
    throw error;
  }
};

export default customFetch;
